<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="基础信息" :bordered='false' style="margin-bottom: 20px;">
      <a-descriptions style="margin-bottom: 30px" :column="4">
        <a-descriptions-item label='商户负责人'>{{ dashboard.masterName }}</a-descriptions-item>
        <a-descriptions-item label='手机号'>{{ dashboard.mobile }}</a-descriptions-item>
        <a-descriptions-item label='分公司'>{{ dashboard.areaShortName }}</a-descriptions-item>
        <a-descriptions-item label='申请时间'>{{dashboard.createTime}}</a-descriptions-item>

        <a-descriptions-item label='兑换积分'>{{dashboard.amount}}</a-descriptions-item>
        <a-descriptions-item label='审核人'>{{dashboard.auditor}}</a-descriptions-item>
        <a-descriptions-item label='审核时间'>{{dashboard.auditTime}}</a-descriptions-item>
        <a-descriptions-item label='兑换状态'>
          <span v-if="dashboard.status=='1'">待审核</span>
          <span v-if="dashboard.status=='2'">兑换中</span>
          <span v-if="dashboard.status=='3'">不通过</span>
          <span v-if="dashboard.status=='4'">已完成</span>
        </a-descriptions-item>

      </a-descriptions>
    </a-card>
    <a-card :title="dashboard.feeRate>0?'订单信息 （扣-服务积分）':'订单信息 （不扣-服务积分)' " :bordered='false'>
      <!--      <a-card-meta style="padding-bottom: 20px">-->
      <!--         <template slot="title">-->
      <!--           <span v-if="dashboard.feeRate>0">订单信息 （扣-服务积分）</span>-->
      <!--           <span v-else>订单信息 （不扣-服务积分)</span>-->
      <!--         </template>-->
      <!--      </a-card-meta>-->
      <a-descriptions style="margin-bottom: 30px" :column="6">
        <a-descriptions-item label='已用兑换积分'>{{ dashboard.usedAmount }}</a-descriptions-item>
        <a-descriptions-item v-if="dashboard.status=='4'" label='退回积分'>{{ dashboard.returnAmount }}</a-descriptions-item>
        <a-descriptions-item v-else label='剩余兑换积分'>{{ dashboard.amount - dashboard.usedAmount }}</a-descriptions-item>
<!--        <a-descriptions-item label='服务积分'>{{ dashboard.feeRate }}</a-descriptions-item>-->
        <a-descriptions-item  :span="4" >
          <a-button type='primary'  @click="showConfirm" v-if="dashboard.exchangeStatus=='1'">
            提前完成
          </a-button>
        </a-descriptions-item>
      </a-descriptions>

      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :scroll='{ x: 1500}' :data='loadData1'>

      </s-table>
    </a-card>

    <a-modal
        v-model:visible="deliver2.visible"
        title="二次确认"
        ok-text="确认"
        cancel-text="取消"
        @ok="finishedThis"
        :maskClosable='true' width="500px"
    >
      是否确认提前完成！
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item  label="剩余退回积分" prop="cash" >
          <a-input v-model='deliver2.form.cash' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'

import moment from 'moment'
import {exchangeFinish, queryDetail, queryExchangeOrderList} from "@/api/branch";



const columns1 = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '发货时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 120,
    title: '兑换商品名称',
    dataIndex: 'productionDesc',
    scopedSlots: { customRender: 'productionDesc' }
  },
  {
    width: 80,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 100,
    title: '收货人手机号',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
  {
    width: 80,
    title: '使用积分',
    dataIndex: 'usedCash',
    scopedSlots: { customRender: 'usedCash' },
  },
  {
    width: 80,
    title: '剩余积分',
    dataIndex: 'remainCash',
    scopedSlots: { customRender: 'remainCash' },
  }
]


const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'ProposalEdit',

  data() {
    return {
      id:'',
      playImg: require('@/assets/play.jpg'),
      loading: false,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
      formatType,
      columns1,
      dashboard: {}, //统计数据
      // 加载数据方法 必须为 Promise 对象
      loadData1: parameter => {
        parameter.id = this.id;
        let params = Object.assign(parameter);
        // debugger
        return queryExchangeOrderList(params).then(datum => {
            // debugger;
            return datum
          })
      },
      deliver1: { visible: false,
        form: {
          start: baseStart,
          end: baseEnd,
        }, loading: false
      },
      deliver2: {
        visible: false,
        form: {
          cash:0
        },
        rules: {
          cash: [{ required: true, message: '请填写退回积分', trigger: 'change' }],
        },
        loading: false
      },
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.handleInitial()
    this.$refs.table1.refresh(true)

  },
  activated() {
    this.handleInitial();
    this.$refs.table1.refresh(true)
    // debugger;
  },
  methods: {

    handleSubmit1() {
      this.handleInitial();
      // this.$refs.table1.refresh(true)
    },
    handleReset1() {
      this.dateArr = [baseStart, baseEnd];
      this.deliver1.form.start = baseStart;
      this.deliver1.form.end = baseEnd;
      // this.$refs.form1.resetFields()
      // this.$refs.table1.refresh(true)
    },
    handleInitial(){
      this.id = this.$route.query.id;
      queryDetail(Object.assign({id:this.id})).then(result => {
        // debugger;
        this.dashboard = result;
        this.loading = false;
      })
    },
    showConfirm(){
      this.deliver2.visible = true;
      this.deliver2.form.cash = this.dashboard.amount - this.dashboard.usedAmount;
    },
    finishedThis(){
      this.$refs.form2.validate(result => {
        if (result) {
          let tempCash = this.dashboard.amount - this.dashboard.usedAmount;
          let cash = this.deliver2.form.cash;
          if(cash>tempCash){
            this.$message.error("剩余退回积分不能大余"+tempCash);
            return;
          }
          exchangeFinish(Object.assign({id:this.id,returnAmount:cash })).then(result => {
            this.handleInitial();
            this.$refs.table1.refresh(true);
            this.$message.success("操作已完成")
            this.deliver2.visible =false;
          }).finally(e=>{
            this.deliver2.visible =false;
          })
        }
      })

    },


  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}

.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
